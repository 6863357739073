@use '../vars.scss';

.nextButton {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    align-items: center;
    border: solid 3px white;
    border-radius: 10px;
    padding: 15px 30px 15px 30px;
    background-color: transparent;
    color: white;
    font-weight: 600;
    font-size: 20px;
    img {
        margin-left: 15px;
    }
}