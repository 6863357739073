@use '../vars.scss';

#captureWrap {
    height: 95%;
    display: flex;
    flex-direction: column;
    //justify-content: space-around;
    align-items: center;
    color: black;
}

#captureCard {
    position: relative;
    border-radius: 20px;
    width: 80%;
    padding: 20px 15px 20px 15px;
    margin-top: 15px;
    margin-bottom: 40px;
    border: solid 3px rgba(218, 223, 229, 0.8);
    background-color: rgba(255,255,255,0.6);
    max-height: 55%;
    height: 350px;
    color: rgba(0,0,0,0.5);
    overflow: hidden;
    #stats {
        display: flex;
        justify-content: space-between;
        align-items: center;
        #leftStat {
            display: flex;
            align-items: center;
            font-size: 25px;
            font-weight: 600;
            img {
                height: auto;
                width: 38px;
                padding-left: 5px;
            }
        }
        #rightStat {
            font-size: 20px;
            font-weight: 500;
        }
    }
    #scanValidation {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0 0 20px 20px;
    height: 70px;
    }
}

.validScan {
    background-color: vars.$successColor !important;
}
.notValidScan {
    background-color: vars.$failureColor !important;
}

.shapeCard {
    display: flex;
    justify-content: center;
    align-items: center;
    #shapeImage {
        position: absolute;
        height: auto;
        width: 150px;
    }
}

#confetti {
    z-index: 5;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
    margin: 0;
    height: 100%;
    position: absolute;
    top: 0;
    pointer-events: none;
}

#cameraContainer {
    width: 100%;
    height: inherit;
    img {
        height: 50%;
        width: 100%;
    }
}

.display {
    display: block !important;
}
.hide  {
    display: none !important;
}

.validProgress {
    border: solid 3px vars.$successColor;
}