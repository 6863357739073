@use 'vars.scss';
@import 'components/_full_width_card';
@import 'components/_progress_card';
@import "components/_next_button";
@import "components/_profile.scss";
@import "components/_home.scss";
@import "components/collection.scss";
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

body {
    color: white;
    font-family: "Roboto", sans-serif;
  background: #4a64b4 url("../../public/img/background.jpg") center;
}

#root {
    height: 100vh;
    //overflow: hidden;
}

p {
    margin: 0;
}
#teamSelection {
    padding-top: 20px;
    //padding-bottom: 40px;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    //justify-content: space-around;
    align-items: center;
    h1 {
        padding-bottom: 20px;
        margin: 0;
    }
}

.teamCard {
    @include vars.transparentCard;
    opacity: 0.5;
    padding-top: 10px;
    padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
    width: 65%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    img {
      margin-bottom: 15px;
      height: auto;
      width: 80px;
    }
    p {
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 1px;
    }
    .teamName {
        font-size: 30px;
        font-weight: 500;
    }

}

.selected {
    transition: all 0.2s ease-out;
    box-sizing: border-box;
    outline: solid 5px white;
    filter: saturate(1);
    opacity: 1;
}



#logoCapture {
  position: relative;
    height: 85px;
    width: 85px;
    border: solid 3px white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        height: auto;
        width: 50px;
    }
  #return {
    position: absolute;
    left: -80px;
  }
}

.valid {
    color: vars.$successColor;
    border: solid 3px vars.$successColor;
}
.notValid {
    color: vars.$failureColor;
    border: solid 3px vars.$failureColor;
}

.validButton {
    background-color: vars.$successColor;

}
.notValidButton {
    background-color: vars.$failureColor;
}

#successWrap {
    overflow: hidden;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    #endBackground {
        height: 100%;
        width: 100%;
        position: absolute;
    }
    #endPopup {
        color: black;
        z-index: 5;
        position: relative;
        background-color: #ffffffbf;
        width: 50%;
        padding: 20px 40px;
        text-align: center;
        border-radius: 15px;
    }
    .nextButton {
        width: 90px;
        margin-left: auto;
    }
}

.nextButton {
  img {
    height: auto;
    width: 35px;
  }
}
#successCard {
    color: #4A40C3;
    z-index: 5;
    position: relative;
    background-color: white;
    border: solid 5px vars.$successColor;
    width: 50%;
    padding: 30px 40px;
    text-align: center;
    border-radius: 15px;
    font-size: 32px;
    font-weight: 600;
    #successImage {
        margin: 30px 0;
    }
}

#cameraScreen {
    width: 100%;
  //height: 400px;
  #pictureResult {
    height: 350px;
    width: 100%;
  }
}

#circleBackground {
    height: 100%;
    width: 100%;
    background: #ffffffab;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease-out;
}
.circle{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 250px;
  border: 5px solid #FFFFFF;
  box-shadow: 0 0 0 5px #4973ff;
  border-radius: 50%;
  overflow: hidden;
    transition: all 0.3s ease-out;
}
.wave{
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  background: #2C74B3;
  border-radius: 50%;
  box-shadow: inset 0 0 50px 0 rgba(0, 0, 0, 0.5);
}
.wave:before,
.wave:after{
  content:'';
  position: absolute;
  top: 0;
  left: 50%;
  width: 200%;
  height: 200%;
  background: black;
  transform: translate(-50%, -75%);
}
.wave:before{
  border-radius: 45%;
  background: rgba(255, 255, 255, 1);
  animation: animate 5s linear infinite;
}
.wave:after{
  border-radius: 40%;
  background: rgba(255, 255, 255, 0.5);
  animation: animate 10s linear infinite;
}

@keyframes animate{
  0%{
    transform: translate(-50%, -75%) rotate(0deg);
  }
  100%{
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

#triggerButton {
  border: none;
  background: transparent;
}

.test {
      position: absolute;
    bottom: 50px;
    right: 5%;
    margin: 0;
    padding: 7px;
}

#authWebcam {
  display: none;
}

#successClose {
      position: absolute;
    top: 8px;
    right: 18px;
}

.obfuscate {
  filter: brightness(0.1);
}

#homeBottomMenu {
    position: absolute;
    bottom: 5%;
    width: 50%;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  img {
    height: auto;
    width: 40px;
  }
}