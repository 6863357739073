@use '../vars.scss';

#profileContainer {
    height: inherit;
    font-family: "Montserrat", sans-serif;
    #profileHeader {
        @include vars.verticalAlignment;
        justify-content: center;
        position: relative;
        background-position: center center;
        background-repeat: no-repeat;
        height: 215px;
        #actions {
            position: absolute;
            top: 20px;
            right: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            div {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, 0.6);
                padding: 7px;
                border-radius: 100%;
                margin-right: 10px;
                img {
                    height: auto;
                    width: 19px;
                }
            }
        }
        #profileImage {
            img {
                width: 115px;
                border: solid 4px white;
                border-radius: 100%;
            }
        }
    }
    #profileBody {
        background: rgb(0,18,55);
        background: -moz-linear-gradient(180deg, rgba(0,18,55,1) 0%, rgba(18,46,104,1) 100%);
        background: -webkit-linear-gradient(180deg, rgba(0,18,55,1) 0%, rgba(18,46,104,1) 100%);
        background: linear-gradient(180deg, rgba(0,18,55,1) 0%, rgba(18,46,104,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#001237",endColorstr="#122e68",GradientType=1);
        transform: translateY(-20px);
        height: inherit;
        #profileInfo {
            text-align: left;
            width: 70%;
            left: 0;
            right: 0;
            margin: auto;
            #name {
                font-weight: 500;
                font-size: 20px;
                margin-bottom: 5px;
            }
            #title, #level {
                font-weight: 300;
                font-size: 15px;
                margin-bottom: 3px;
            }
            #level {
                font-size: 12px;
            }
        }
        #highlightsContainer {
            display: flex;
            justify-content: space-between;
            text-align: center;
            margin-top: 15px;
            div {
                @include vars.profileBlock;
                img {
                    height: auto;
                    width: 35px;
                }
            }
             #leftHighlight {
                 position: relative;
                 background: linear-gradient(90deg, rgba(215,215,215,0) 20%, rgba(215,215,215,0.5) 100%);
                 border-radius: 0 15px 15px 0;
             }
            #middleHighlight {
                position: relative;
                 background: linear-gradient(90deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0.3) 100%);
                 border-radius: 15px;
            }
            #rightHighlight {
                position: relative;
                 background: linear-gradient(270deg, rgba(215,215,215,0) 20%, rgba(215,215,215,0.5) 100%);
                 border-radius: 15px 0 0 15px;
            }
            .statNumber {
                padding-top: 5px;
                font-size: 24px;
                font-weight: 400;
            }
            .statName {
                color: #CECECE;
                font-size: 14px;
                font-weight: 300;
            }
        }
        #achievementsWrap {
            @include vars.profileBlock;
            width: 90%;
            height: 20%;
            left: 0;
            right: 0;
            margin: 30px auto auto;
            background: linear-gradient(0deg, rgba(215,215,215,0) 40%, rgba(215,215,215,0.3) 100%);
            border-radius: 15px;
            p {
                margin-bottom: 15px;
            }
            #achievementCount {
                font-size: 14px;
                font-weight: 200;
            }
            #achievementsContainer {
                img {
                    width: 30px;
                    margin-right: 15px;
                    border: solid 2px grey;
                    border-radius: 100%;
                    padding: 6px;
                    filter: grayscale(0.7);
                }
            }
        }
    }
}

.achievementUnlocked {
    border: solid 2px #ED495C !important;
    background-color: rgba(240,75,90,0.3) !important;
    filter: grayscale(0) !important;
}
