@use '../vars.scss';

#collectionBody {
  color: black;
  background-color: white;
  padding: 30px 15px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 12px;
  #filterMenu {
    display: flex;
    align-items: center;
    input {
      border: solid 1px rgba(0, 0, 0, 0.11);
      padding: 10px;
      width: 80%;
      border-radius: 10px;
      -webkit-box-shadow: inset 0px 3px 8px -1px rgba(0, 0, 0, 0.15);
      box-shadow: inset 0px 3px 8px -1px rgba(0, 0, 0, 0.15);
      margin-right: 15px;
    }
    img {
      height: auto;
      width: 25px;
    }
  }
  #collectionContainer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
  .collectionCard {
    -webkit-box-shadow: 5px 4px 10px -4px rgba(0, 0, 0, 0.25);
    box-shadow: 5px 4px 10px -4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    height: max-content;
    width: 45%;
    margin-bottom: 15px;
    .collectionPicture {
      height: 50%;
      width: 100%;
    }
    .collectionName, .collectionData {
      padding: 5px;
    }
    .collectionData {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 10px;
      font-weight: 400;
      img {
        height: 18px;
        width: 18px;
      }
      div {
        display: flex;
        align-items: center;
      }
      :nth-child(1) {
        margin-right: 5px;
      }
    }
  }
}

#speciesPopup {
  height: 100%;
  font-family: Montserrat, sans-serif;
  position: fixed;
  top: 0;
  background-color: white;
  color: black;
  width: 100% ;
  #closeSpecies {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  #headerSpecies {
    position: relative;
  }
  #menuSpecies {
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    bottom: 0;
    transform: translateY(20px);
    display: flex;
    height: 40px;
    width: 75%;
    left: 0;
    right: 0;
    margin: auto;
    background-color: white;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    -webkit-box-shadow: 0px 4px 15px 0px rgba(0,0,0,0.14);
    box-shadow: 0px 4px 15px 0px rgba(0,0,0,0.14);
    #status {
      transform: translateY(4px);
      border-radius: 0 0 15px 15px;
    }
  }
  #speciesWrap {
    padding-top: 35px;
    width: 90%;
    left: 0;
    right: 0;
    margin: auto;
    #speciesName {
      :nth-child(1) {
        font-size: 20px;
        font-weight: 900;
      }
      :nth-child(2) {
        font-size: 15px;
        font-weight: 300;
      }
    }
  }
  #simpleContainer {
    display: flex;
    img {
      height: auto;
      width: 40px;
    }
  }
  #speciesDataContainer {
    #speciesText {
      height: 170px;
      overflow: scroll;
      font-size: 12px;
    }
    #pictoContainer {
      margin-top: 15px;
      display: flex;
      justify-content: space-evenly;
      div {
        text-align: center;
        width: 25%;
        font-size: 11px;
        font-weight: 700;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
      }
      img {
      height: auto;
      width: 40px;
    }
    }
  }
}

.activeDisplay {
  background-color: #0E486F;
    height: 4px;
}
