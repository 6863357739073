@use '../vars.scss';
#home {
    @include vars.verticalAlignment;
    padding-top: 100px;
}

#hero {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Satisfy, serif;
    font-size: 50px;
    font-weight: 500;
}

#pinInput {
    color: #202020;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    #pin {
        margin-top: 160px;
        background-color: rgba(255, 255, 255, 0.8);
        border: solid 3px rgba(106, 132, 153, 0.6);
        padding: 15px;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #202020;
        font-family: Roboto;
        width: 70%;
    }
}


#submitPin {
    margin-top: 50px;
    font-size: 30px;
    font-weight: 600;
}
