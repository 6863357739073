@use '../vars.scss';

#progressWrap {
    display: flex;
    align-content: space-between;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-top: 10%;
    color: black;
    .progressCard {
        @include vars.transparentCard;
        @include vars.verticalAlignment;
        background-color: rgba(255,255,255,0.8);;
        width: 40%;
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 25px 0px;
        font-weight: 600;
        img {
            margin-bottom: 15px;
            height: auto;
            width: 50%;
        }
    }
    .nextButton {
        margin-top: 75px;
        img {
            height: auto;
            width: 30px;
        }
    }
}

#return {
    img {
        transform: rotateZ(180deg);
        height: auto;
        width: 50px;
    }
}


