$cardColor : rgba(255,255,255,0.5);
$successColor : rgba(9, 180, 26, 0.8);
$failureColor : rgba(209, 64, 64, 0.8);

@mixin transparentCard {
  background-color: $cardColor;
  border-radius: 20px;
}

@mixin verticalAlignment {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin profileBlock {
  width: 24%;
  padding: 8px 10px 12px 10px;
  box-shadow: inset 0 0 3px #ffffff;
  border: solid 1px rgba(255, 255, 255, 0.62);
}